import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import Footer from "../Components/Home/Footer/Footer";
import { Navbar } from '../Components/Home/Header/Navbar';

const Main = () => {
    return (
        <>
             <Helmet>
        <title>Robe Sourcing </title>
                <meta
                    name="description"
                    content="Robe sourcing limited, Website Created by {https://www.facebook.com/sabbirhossen58sh58}"
                />
                <meta name='keywords' content='robe, Robe, Sourcing, Source,textile,sourcing company,product sourcing,global sourcing' />
        <link rel="canonical" href="/" />
      </Helmet>
            <Navbar></Navbar>
            <Outlet></Outlet>
            <Footer></Footer>
            
            
        </>
    );
};

export default Main;
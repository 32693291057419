import React from "react";

const Woven = () => {
  return (
    <div className="text-center">
      <h2 className=" text-red-400">Woven Products Updates Soon!</h2>
    </div>
  );
};

export default Woven;

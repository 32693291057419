import React, { useState } from "react";
import { Link } from "react-router-dom";

//css
import "./../Footer/footer.css";
const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="relative m-[-8px] ">
        <button
          className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:shadow-outline-gray"
          onClick={toggleDropdown}
        >
          Products
          {/* <svg
            className={`${
              isOpen ? "transform rotate-180" : ""
            } text-gray-400 h-4 w-4 ml-1`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg> */}
        </button>
        <div
          className={`w-max ${
            isOpen ? "block" : "hidden"
          } absolute z-10 bg_back  rounded-md shadow-lg mt-1`}
        >
          <Link
            className="block px-4 py-2 text-sm text-gray-300 hover:text-white"
            to="/kidswear"
          >
            KIDSWEAR
          </Link>
          <Link
            className="block px-4 py-2 text-sm text-gray-300 hover:text-white"
            to="/womenproduct"
          >
            WOMEN’S CLOTHING
          </Link>
          <Link
            className="block px-4 py-2 text-sm text-gray-300 hover:text-white"
            to="/menproduct"
          >
            MEN’S CLOTHING
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;

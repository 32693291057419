import React from "react";
import { Helmet } from "react-helmet-async";
import service_ps from "../../Image/cervice_p4s.png";
import service_pm from "../../Image/service_p3m.png";
import service_pr from "../../Image/service_pr.png";
import service_prd from "../../Image/service_prd.png";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Services -RSL</title>
        <meta
          name="description"
          content="Service now Robe sourcing limited, Website Created by {https://www.facebook.com/sabbirhossen58sh58}"
        />
        <link rel="canonical" href="/service" />
      </Helmet>

      <h2 className="text-center p-5 text-2xl font-bold font-mono">
        OUR SERVICES
      </h2>
      <div className="zn-bgSource-image  grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1  gap-5 py-6 justify-center justify-items-center px-4">
        <div className="bg-sky-100 text-start p-5 rounded-md">
          <img
            className="w-48 h-32 mx-auto"
            src={service_pr}
            alt="service_logo"
          />
          <h1 className="text-lg text-center py-3  font-bold	 font-mono">
            Sourcing
          </h1>
          <p className="text-sm">
            We have dedicated teams in Asia responsible for sourcing and
            developing the latest fabrics, finishes and washes ahead of the
            market.
          </p>
        </div>
        <div className="bg-sky-100 text-start p-5 rounded-md">
          <img
            className="w-48 h-32 mx-auto "
            src={service_prd}
            alt="service_logo"
          />
          <h3 className="text-[97%]  text-center py-4 font-bold font-mono">
            Research & Development
          </h3>
          <p className="text-sm">
            R&D is the first step in creating new, innovative collections. Robe
            sourcing International's Technical Support teams create samples that
            suit your unique requirements in order to bring your vision to life.
          </p>
        </div>
        <div className="bg-sky-100 text-start p-5 rounded-md">
          <img
            className="w-48 h-32 mx-auto"
            src={service_pm}
            alt="service_logo"
          />
          <h3 className="text-lg text-center py-3  font-bold	 font-mono">
            Production
          </h3>
          <p className="text-sm ">
            On-site technical experts oversee and control the quality at our
            factories. In addition, our experienced Quality Control teams offer
            our partners the transparency and control required to effectively
            measure the quality of each order.
          </p>
        </div>
        <div className="bg-sky-100 text-start p-5 rounded-md">
          <img
            className="w-30 h-20 mt-4 mx-auto"
            src={service_ps}
            alt="service_logo"
          />
          <h3 className="text-lg text-center py-3  mt-6 font-bold font-mono">
            Operation Support
          </h3>
          <p className="text-sm  ">
            We handle each step of the manufacturing process for you. From
            sourcing raw materials to shipping the finished product, our
            internal teams manage every phase of the clothing supply chain.
          </p>
        </div>
      </div>
    </>
  );
};

export default Services;



function Loading  () {
    return (
        <div>
            <h1 className="text-center">Loading suspence 1</h1>
            <div className="grid grid-cols-4 gap-5 mx-52">
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
                 <div className="w-32 h-28 bg-gray-200 border rounded text-center" > ...</div> 
            </div> 
        </div>
    );
};
export default Loading;
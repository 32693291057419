import React from "react";

const KidWoven = () => {
  return (
    <div className="text-center">
      <h2 className=" text-red-400">Kid Woven Products Updates Soon!</h2>
    </div>
  );
};

export default KidWoven;

import React from "react";

const Quality = () => {
   const [isHovering, setIsHovering] = React.useState(false);
   const [isHovering1, setIsHovering1] = React.useState(false);
   const [isHovering2, setIsHovering2] = React.useState(false);
   return (
     <div className="mb-5 w-full">
       <div
         className="text-gray-400 text-center font-extrabold mb-10
       "
       >
         . . .
       </div>
       <div className="grid grid-cols-3 mx-[15%]  gap-4">
         <div
           className=" relative text-start w-full h-fit border-4 rounded bg-gray-200  hover:bg-slate-50  p-7 hover:pb-0"
           onMouseEnter={() => setIsHovering(true)}
           onMouseLeave={() => setIsHovering(false)}
         >
           <div className="text-sm ">
             <div className=" font-sans text-2xl mb-5  ">QUALITY</div>
             From the first sample, we take care of the quality at every step,
             we identify the key points of each product to anticipate possible
             difficulties in production.
             <br />
             <div
               className={`absolute ${
                 isHovering ? "block" : "hidden"
               } bg-gray-200 text-start text-sm w-full border-t-0  border-4   hover:bg-slate-50 rounded-b  pt-0.5 p-7 ml-[-13%]`}
             >
               We use the Lectra system to create and print the patterns which
               we then pass on to the manufacturers. All of these actions are
               meant to decrease the margin of error. Our in-house lab allows us
               to run the most common tests on each and every order. Our Quality
               Department is totally independent from the Merchandising and
               Production departments, their only goal is to achieve AQL quality
               requirement.
             </div>
           </div>
         </div>
         <div
           className=" relative text-start w-full h-fit border-4 rounded bg-gray-200  hover:bg-slate-50  p-5 hover:pb-0 hover:mb-5"
           onMouseEnter={() => setIsHovering1(true)}
           onMouseLeave={() => setIsHovering1(false)}
         >
           <div className=" text-sm">
             <div className="font-sans text-2xl mb-5 ">INNOVATION</div>
             We are constantly looking for new fabrics, accessories, washes,
             print and embroidery techniques. To do so, we use our different
             production offices as a multi-sourcing network to offer the
             greatest variety to our customers.
             <br />
             <div
               className={`absolute ${
                 isHovering1 ? "block" : "hidden"
               } bg-gray-200 text-start text-sm w-full border-t-0  border-4   hover:bg-slate-50 rounded-b  pt-0.5 p-7 ml-[-10%]`}
             >
               We are also constantly trying to improve the production processes
               in our own factories as well as our partner’s. We also made the
               decision to locate our Design and Graphic Departments in Dhaka
               (Bangladesh) to be as close as possible to the production
               facilities, encouraging and pushing them to perform in innovation
               and development to meet the ever changing needs of the customer.
               Our expertise in Denim is highly appreciated by our customers.
             </div>
           </div>
         </div>
         <div
           className=" relative text-start w-full h-fit border-4 rounded bg-gray-200  hover:bg-slate-50  p-5 hover:pb-0"
           onMouseEnter={() => setIsHovering2(true)}
           onMouseLeave={() => setIsHovering2(false)}
         >
           <div className=" text-sm">
             <div className="font-sans text-2xl mb-5">COMPLIANCE</div>
             Our production network is based on compliance, we guarantee our
             customers that their goods are produced in factories where the
             workers’ rights are respected.
             <br />
             <div
               className={`absolute ${
                 isHovering2 ? "block" : "hidden"
               } bg-gray-200 text-start text-sm w-full border-t-0  border-4   hover:bg-slate-50 rounded-b  pt-0.5 p-6 ml-[-10%]`}
             >
               We have our own Compliance Department that constantly evaluates
               the factories and provides them with mandatory follow-up
               improvements. We constantly strive to improve the working
               conditions, safety and benefits of the workers. This is made
               possible by a long term partnership with our clients and
               manufacturers. We are following international standards and code
               of conduct such as BSCI, Accord, Wrap, ICS…
             </div>
           </div>
         </div>
       </div>
     </div>
   );
};

export default Quality;

// import { Route, Routes } from "react-router-dom";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
// import Footer from "./Components/Home/Footer/Footer";
// import { Navbar } from "./Components/Home/Header/Navbar";
import { Home } from "./Components/Home/Home";
// import MenProduct from "./Components/pages/Products/Men's_cloth/MenProduct";
import { Suspense, lazy } from "react";
import Abouts from "./Components/pages/About/Abouts";
import Contacts from "./Components/pages/Contacts/Contacts";
import KidKnit from "./Components/pages/Products/KIDSWEAR/Kid's_Cloth_list/Knit/KidKnit";
import KidSweater from "./Components/pages/Products/KIDSWEAR/Kid's_Cloth_list/Sweater/KidSweater";
import KidWoven from "./Components/pages/Products/KIDSWEAR/Kid's_Cloth_list/Woven/KidWoven";
import Knit from "./Components/pages/Products/Men's_cloth/Men's_cloth_list/Knit/Knit";
import Sweater from "./Components/pages/Products/Men's_cloth/Men's_cloth_list/Sweater/Sweater";
import Woven from "./Components/pages/Products/Men's_cloth/Men's_cloth_list/Woven/Woven";
import Services from "./Components/pages/Service/Services";
import Loading from "./Hooks/Loading";
// import KidProductlist from "./layout/KidProductlist";
import Main from "./layout/Main";
// import MenProductlist from "./layout/MenProductlist";

import WomenProductlist from "./layout/WomenProductlist";

const MenProductlist =lazy(()=> import('./layout/MenProductlist'))
const KidProductlist =lazy(()=> import('./layout/KidProductlist'))
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <Home></Home>
        },
        
       

        {
          path: "about",
          element: <Abouts></Abouts>
        },
        {
          path: "service",
          element: <Services />
        },
        
        
        {
          path: "contacts",
          element: <Contacts />
        }
        
      ]
    },
    {
      path: "kidswear",
      element: <Suspense Suspense fallback={< Loading />} > <KidProductlist /></Suspense >,
       loader: async() => {
            return fetch("./kidproduct.json")
      },
      children: [
        {    
         path: ":kidknit",
         element:<KidKnit/>
        },
        {    
         path: ":kidwoven",
         element:<KidWoven/>
        },
        {
          path: ":kidsweater",
          element:<KidSweater/>
        }
       ]
          
    },
    {
      path: "womenproduct",
      element: <WomenProductlist/>,
         
    },
    {
      path: "menproduct",
      element: <Suspense fallback={<Loading/>}><MenProductlist/></Suspense>,
       loader: async() => {
            return fetch("./menproduct.json")
          },
          
      children: [
         
        {    
         path: ":menknit",
         element:<Knit/>
        },
        {    
         path: ":menwoven",
         element:<Woven/>
        },
        {
          path: ":mensweater",
          element:<Sweater/>
        }
      ]    
    },
    {
      path: "*",
      element:<div> Router not a correct</div>
    }

    
    

  ])
  

  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
     
    </div>
  );
}

export default App;

import React from "react";
import About from "../pages/About/About";
import Service from "../pages/Service/Service";
import Quality from "./FrontHomePage/Quality.jsx";
import Slide from "./FrontHomePage/Slide";


export const Home = () => {
  return (
    <>
      {/* <Helmet>
        
        <meta name="description" content="Our home page design by Sabbir hossen" />
        <link rel="canonical" href="/" />
      </Helmet> */}
     
      <Slide />
      
      <About />
      <Quality />
      <Service></Service>
    </>
  );
};

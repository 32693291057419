import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Footer from '../Components/Home/Footer/Footer';
import { Navbar } from '../Components/Home/Header/Navbar';
const WomenProductlist = () => {
    return (
        <>
        <Navbar></Navbar>
        <Helmet>
        
        <title>Women products-RSL</title>
        <meta name="description" content="Shop our latest Women products now" />
        <link rel="canonical" href="/womenproduct" />
      </Helmet>

         <div className="my-5">
        <div className="border mx-auto lg:w-4/12 p-10 items-center   ">
          <h1 className="font-medium text-2xl my-4 text-center text-yellow-500">
            Women Products page 
          </h1>
          <h2 className="font-medium text-xl my-4 text-center text-blue-500">
            Please contact me: forid@robesourcing.com
          </h2>
          <h1 className="font-medium text-2xl"> Feature coming soon!</h1>
          <div>
            <h2 className="pt-5 pb-3 font-thin">Under Development</h2>
            <h6 className="pr-7">
              The feature you were looking for is being developed and will be
              available soon. Thanks for your patience.
            </h6>
          </div>
          <div className="my-3">
            <Link to="/">
              <button className="border-4 p-2 hover:border-green-500 rounded-full hover:bg-yellow-500 ">
                Go to home
              </button>
            </Link>
          </div>
        </div>
      </div>
            
             {/* <div className=" text-black ">
      
      <dev className=" grid grid-cols-3  py-6">
        <div className="  border-r-4 mr-8 border-gray-400 pl-24" id="dfh">
          <div className='text-md font-bold font-mono'>Women's PRODUCTS LIST</div>
          <div>
            <Link
              className="block  text-sm text-black hover:text-blue-400"
              to="womenwoven"
                >
                  <div className="flex justify-start items-center">
                    <div>
                      <img className='w-2 mx-1' src={img_s} alt="arrow" />
                    </div>
                    <div>   WOVEN</div>
                  </div>
             
            </Link>
            <Link
              className="block  text-sm text-black hover:text-blue-400"
              to="womenknit"
                >
                   <div className="flex justify-start items-center">
                    <div>
                      <img className='w-2 mx-1' src={img_s} alt="arrow" />
                    </div>
                    <div>KNIT</div>
                  </div>
                  
              
            </Link>
            <Link
              className="block text-sm  text-black hover:text-blue-400"
              to="womensweater"
                >
                  <div className="flex justify-start items-center">
                    <div>
                      <img className='w-2 mx-1' src={img_s} alt="arrow" />
                    </div>
                    <div> SWEATER</div>
                  </div>
             
            </Link>
           
            <Link
              className="block   text-md  text-black font-bold font-mono hover:text-blue-400"
              to="/kidswear"
                >
                  <div className="flex justify-start items-center">
                    <div>
                      <img className='w-3 mr-1' src={img} alt="arrow" />
                    </div>
                    <div>  Kid's PRODUCT'S</div>
                  </div>
             
                </Link>
                <Link
              className="block   text-md  text-black font-bold font-mono hover:text-blue-400"
              to="/menproduct"
                >
                  <div className="flex justify-start items-center">
                    <div>
                      <img className='w-3 mr-1' src={img} alt="arrow" />
                    </div>
                    <div> MEN PRODUCT'S</div>
                  </div>
              
                </Link>
               
          </div>
        </div>

        <div className="col-span-2" id="dfh">
              <Outlet />
              
             <Womenproducts  products={products} />
        </div>
      </dev>
    </div> */}
            
            <Footer></Footer>
           
           
            
            
        </>
    );
};

export default WomenProductlist;
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

const Contacts = () => {
  const [name, setName] = useState("");
  const [subject, setsubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("Subject:", subject);
    console.log("Email:", email);
    console.log("Message:", message);
    setName(" ");
    setEmail(" ");
    setsubject(" ");
    setMessage(" ");
  };
  return (
    <>
      <Helmet>
        <title>Contacts -RSL</title>
        <meta
          name="description"
          content="Contact now Robe sourcing limited, Website Created by {https://www.facebook.com/sabbirhossen58sh58} "
        />
        <link rel="canonical" href="/contacts" />
      </Helmet>

      <div className="w-full">
        <div className="text-center text-[27px] mt-7  mb-10">
          <div className="mb-2 font-medium" style={{ fontFamily: "Raleway" }}>
            {" "}
            WE WILL BE HAPPY TO ANSWER YOUR QUESTIONS.
          </div>
          <div className=" font-thin font-mono  text-xs">
            Fill out the form and we will get back to you shortly.
          </div>
        </div>
        <div className="flex justify-center items-center h-screen">
          <form onSubmit={handleSubmit} className="w-96">
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Enter Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="name"
              >
                Subject
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Enter Your Subject"
                value={subject}
                onChange={(e) => setsubject(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="email"
              >
                Gmail
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                required
                placeholder="example@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                rows="5"
                placeholder="Enter your message here"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="flex justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-progress ..."
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contacts;

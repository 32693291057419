import React from "react";
import about_1 from "./../../Image/about6.jpg";
import about_4 from "./../../Image/about_4.jpg";
import about_3 from "./../../Image/about_6.jpg";
import about_2 from "./../../Image/panth_bannar.jpg";
const About = () => {
  return (
    <>
      <div className="flex flex-row justify-items-center mx-[15%] py-10 space-x-5">
        <div className="basis-2/4 text-start">
          <div
            className="text-start font-medium text-[60px]"
            style={{ fontFamily: "Raleway" }}
          >
            Who we are
          </div>
          <br />
          <div>
            <div className="text-sm  font-serif">
              <h1 className="text-md">
                " ROBE SOURCING LIMITED " is prominent readymade garment buying
                agent.
              </h1>
              <br />
              ROBE SOURCING LIMITED is a pioneering, dynamic, professionally
              managed business organization that has strong experience in the
              field of readymade garments sector with world-class service,
              quality solutions and value addition services to end products for
              the textile industries on a global level, beginning from fabrics
              to fashion through market knowledge, experienced sourcing
              professionals
            </div>
          </div>
        </div>
        <div className="basis-2/4 flex justify-center items-center">
          <div className=" grid grid-cols-2  gap-2">
            <img className="w-full h-full rounded" src={about_1} alt="" />
            <img className="w-full h-full rounded" src={about_2} alt="" />
            <img className="w-full h-32 rounded" src={about_3} alt="" />
            <img className="w-full h-auto rounded" src={about_4} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

import React from "react";

const Knit = () => {
  return (
    <div className="text-center">
      <h2 className=" text-red-400">knit Products Updates Soon!</h2>
    </div>
  );
};

export default Knit;

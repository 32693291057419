import React from "react";

const Sweater = () => {
  return (
    <div className="text-center">
      <h2 className=" text-red-400">Sweater Products Updates Soon!</h2>
    </div>
  );
};

export default Sweater;
